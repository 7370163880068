<template>
  <section class="section" style="padding-top: 1.5rem;">
    <div class="container Nunito">
      <div class="columns">
        <div class="column is-half">
          <h1 class="title has-text-white" style="font-size: 25px; margin-bottom: 1.5rem;">{{ $t("features.notifications.title") }}</h1>
          <p class="subtitle is-size-5 has-text-white" style="font-size: 15px;padding-left: 10px;">{{ $t("features.notifications.desc") }}</p>
        </div>
        <div class="column is-half" style="width: fit-content;">
          <img style="cursor: pointer;" @mouseover="$('#notificationviewimg')[0].alt = 'Notification Preview 1-3'; $('#notificationviewimg')[0].src = 'https://cdn.gracebot.net/grace/previews/view1-3.png';" @mouseleave="$('#notificationviewimg')[0].alt = 'Notification Preview 1-1'; $('#notificationviewimg')[0].src = 'https://cdn.gracebot.net/grace/previews/view1-1.png';" class="preview-img" id="notificationviewimg" src="https://cdn.gracebot.net/grace/previews/view1-1.png" alt="Notification Preview 1-1" />
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <img class="preview-img" src="https://cdn.gracebot.net/grace/previews/view2-0.png" alt="Reaction Roles 2-0" />
        </div>
        <div class="column is-half">
          <h1 class="title has-text-white" style="font-size: 25px; margin-bottom: 1.5rem;">{{ $t("features.reaction.title") }}</h1>
          <p class="subtitle is-size-5 has-text-white" style="font-size: 15px;padding-left: 10px;">{{ $t("features.reaction.desc") }}</p>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <h1 class="title has-text-white" style="font-size: 25px; margin-bottom: 1.5rem;">{{ $t("features.xp.title") }}</h1>
          <p class="subtitle is-size-5 has-text-white" style="font-size: 15px;padding-left: 10px;">{{ $t("features.xp.desc") }} <router-link tag="a" :to="{ path: '/leaderboard/521408841485910017' }">leaderboards</router-link>!</p>
        </div>
        <div class="column is-half" style="width: fit-content;">
          <img style="cursor: pointer;" @mouseover="$('#xppreviewimg')[0].alt = 'Xp Preview 3-0'; $('#xppreviewimg')[0].src = 'https://cdn.gracebot.net/grace/previews/view3-0.png';" @mouseleave="$('#xppreviewimg')[0].alt = 'Xp Preview 3-2'; $('#xppreviewimg')[0].src = 'https://cdn.gracebot.net/grace/previews/view3-2.png';" class="preview-img" id="xppreviewimg" src="https://cdn.gracebot.net/grace/previews/view3-2.png" alt="Xp Preview 3-2" />
          <!-- <img class="preview-img" src="https://cdn.gracebot.net/grace/previews/view3-0.png" style="" alt="Xp Preview 3-0" /> -->
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <img class="preview-img" src="https://cdn.gracebot.net/grace/previews/view4-0.png" alt="Music 4-0" />
        </div>
        <div class="column is-half">
          <h1 class="title has-text-white" style="font-size: 25px; margin-bottom: 1.5rem;">{{ $t("features.music.title") }}</h1>
          <p class="subtitle is-size-5 has-text-white" style="font-size: 15px;padding-left: 10px;">{{ $t("features.music.desc") }}</p>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <h1 class="title has-text-white" style="font-size: 25px; margin-bottom: 1.5rem;">{{ $t("features.communicate.title") }}</h1>
          <p class="subtitle is-size-5 has-text-white" style="font-size: 15px;padding-left: 10px;">{{ $t("features.communicate.desc") }}</p>
        </div>
        <div class="column is-half" style="width: fit-content;">
          <img style="cursor: pointer;" @mouseover="$('#community')[0].alt = 'Poll Preview 5-1'; $('#community')[0].src = 'https://cdn.gracebot.net/grace/previews/view5-1.png';" @mouseleave="$('#community')[0].alt = 'Poll Preview 5-0'; $('#community')[0].src = 'https://cdn.gracebot.net/grace/previews/view5-0.png';" class="preview-img"  id="community" src="https://cdn.gracebot.net/grace/previews/view5-0.png" alt="Poll Preview 5-0" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

  export default {
    name: 'Features',
    data () {
      return {}
    },
    created () {
    },
    methods: {
    }
  }
</script>

<style scoped>
    a {
        transition: .2s !important;
    }
.preview-img {
  box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.3) !important;
  border-radius: 15px;
  /* cursor: pointer; */
  /* transition: .35s; */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* .preview-img:hover {
  transform: scale(1.05);
  transition: all .35s ease-in-out;
  filter: brightness(.5);
  -webkit-filter: brightness(.5);
  overflow: hidden;
} */

.has-shadow {
  box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
}
</style>
